.burger_right_wrapper {

    position: relative;
    display : inline-block;

    .burger_right {
        background-image   : url('../../assets/icons/menu_bullets.png');
        background-repeat  : no-repeat;
        background-size    : 25px;
        background-position: center;
        width              : 40px;
        height             : 40px;
        cursor             : pointer;
        z-index            : 99;
        background-color   : white;
        border-radius      : 40px;
        z-index            : 9;
        position           : absolute;
        right              : 0;
    }

    &:hover {
        .content {
            display: block;
        }
    }

    .content {
        display         : none;
        position        : absolute;
        right           : 0;
        top             : 20px;
        border-radius   : 15px;
        background-color: white;
        padding         : 15px 25px;
        padding-top     : 20px;
        padding-bottom  : 5px;
        box-shadow      : 0 3px 10px rgba(0, 0, 0, 0.08);
        z-index         : 8;

        div,
        a {
            font-weight    : 400;
            cursor         : pointer;
            transition     : 0.2s ease all;
            text-decoration: none;
            display        : block;
            width          : max-content;
            margin-bottom  : 10px;

            &:hover {
                color: var(--green-color)
            }
        }
    }
}